import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  IDENTIFIER,
  EXPIRES_IN,
  LOGIN_PEOPLE_COMPANY,
  LOGIN_USER_PEOPLE,
  IS_B2B,
  IS_CALL_CENTER,
  IS_CUSTOMER,
  IS_EMPLOYEE,
  LOGIN_PEOPLE_DETAIL,
  LOGIN_NAME,
  LOGIN_PHONE_PREFIX,
  LOGIN_PHONE,
  LOGIN_SOCIAL,
  SOCIAL_TOKEN,
  B2B_ClASS_TYPE,
  ARRANGER_PEOPLE_ID,
} from "../config/constants";
import {
  ARRANGER_LIST_PAGE,
  CREATE_NEW_ARRANGER,
  EMPLOYEE_B2B_SEARCH,
  SET_AS_ARRANGER,
} from "../pages/employeepages/EmployeeConfig";

export const removeFromLocalStorage = () => {
  console.log("removeLocal");
  window?.ma?.removeStorage({
    key: "access_token",
    success(res) {
      console.log(res, "remove access_token");
    },
    fail(er) {
      console.log("fail  access_tokenP", er);
    },
  });
  window?.ma?.removeStorage({
    key: "token_type",
    success(res) {
      console.log(res, "remove token_type");
    },
  });
  window?.ma?.removeStorage({
    key: "is_customer",
    success(res) {
      console.log(res, "remove is_customer");
    },
  });
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(EXPIRES_IN);
  localStorage.removeItem(IDENTIFIER);
  localStorage.removeItem(LOGIN_USER_ID);
  localStorage.removeItem(LOGIN_PEOPLE_COMPANY);
  localStorage.removeItem(LOGIN_USER_PEOPLE);
  localStorage.removeItem(IS_B2B);
  localStorage.removeItem(IS_CALL_CENTER);
  localStorage.removeItem(IS_CUSTOMER);
  localStorage.removeItem(IS_EMPLOYEE);
  localStorage.removeItem(LOGIN_PEOPLE_DETAIL);
  localStorage.removeItem(LOGIN_NAME);
  localStorage.removeItem(LOGIN_PHONE_PREFIX);
  localStorage.removeItem(LOGIN_PHONE);
  localStorage.removeItem(LOGIN_SOCIAL);
  localStorage.removeItem(SOCIAL_TOKEN);
  localStorage.removeItem(B2B_ClASS_TYPE);
  localStorage.removeItem(ARRANGER_PEOPLE_ID);
  localStorage.removeItem("userData");
  localStorage.removeItem("companyPayment");
  localStorage.removeItem(SET_AS_ARRANGER);
  localStorage.removeItem(EMPLOYEE_B2B_SEARCH);
  localStorage.removeItem(ARRANGER_LIST_PAGE);
  localStorage.removeItem(CREATE_NEW_ARRANGER);
};

export const removePassengerInfo = () => {
  sessionStorage.removeItem("adultpassenger");
  sessionStorage.removeItem("childpassenger");
  sessionStorage.removeItem("infantpassenger");
  sessionStorage.removeItem("contactInfo");
  sessionStorage.removeItem("resId");
  sessionStorage.removeItem("pnrFailDept");
  sessionStorage.removeItem("pnrFailReturn");
  sessionStorage.removeItem("PNRfailCount");
  sessionStorage.removeItem("PNRfailReturnCount");
};
