import {
  B2B_ClASS_TYPE,
  IS_CUSTOMER,
  IS_B2B,
  B2BCLASSTYPE_B_ID,
  B2BCLASSTYPE_N_ID,
  B2C_ID,
  B2BCLASSTYPE_C_ID,
  GUEST_ID,
  B2BCLASSTYPE_E_ID,
  B2BCLASSTYPE_S_ID,
  B2BCLASSTYPE_B_ID_NAME,
  B2BCLASSTYPE_N_ID_NAME,
  B2C_ID_NAME,
  B2BCLASSTYPE_C_ID_NAME,
  B2BCLASSTYPE_E_NAME,
  B2BCLASSTYPE_S_NAME,
  IS_CALL_CENTER,
  IS_EMPLOYEE,
} from "./constants";

let isCus = "no";
window?.ma?.getStorage({
  key: "is_customer",
  success(res) {
    console.log("authres (raw response):", res);
    try {
      // Check if `res` is a valid JSON string or already an object
      const isCus = res;

      // Validate token and set isTokenAvailable accordingly

      console.log("| isTokenAvailable:", isCus);
    } catch (e) {
      console.error("Error parsing access token:", e);
      isCus = "no";
    }
  },
  fail(err) {
    console.log("authreser", err);
    isCus = "no";
  },
});

export const IS_CUSTOMER_VAL =
  (window.location.search.includes("miniapp") && window.ma
    ? window?.ma?.getStorage({
        key: "is_customer",
        success(res) {
          console.log("authres (raw response):", res);

          try {
            // Check if `res` is a valid JSON string or already an object
            const isCus = res;
            console.log("| IS_CUSTOMER_VAL:", res);
            // Validate token and set isTokenAvailable accordingly
            return res;
          } catch (e) {
            console.error("Error parsing access token:", e);
            isCus = "no";
          }
        },
        fail(err) {
          console.log("authreser", err);
          isCus = "no";
        },
      })
    : localStorage.getItem(IS_CUSTOMER)) === "yes"
    ? true
    : false;

//   ? window.ma.getStorage({
//       key: "is_customer",
//       success(res) {
//         console.log("isCuss", res);
//         return res;
//       },
//     })
//   : localStorage.getItem(IS_CUSTOMER)) &&
// (window.location.search.includes("miniapp") && window.ma
//   ?
// window?.ma?.getStorage({
//   key: "is_customer",
//   success(res) {
//     console.log("isCuss", res);
//     return  isCus=? true : false;
//   },
// });
// localStorage.getItem(IS_CUSTOMER)) === "yes"
//   true
// : false;

export const IS_B2B_VAL =
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_B2B,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_B2B)) &&
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_B2B,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_B2B)) === "yes"
    ? true
    : false;
export const IS_CALLCENTER_VAL =
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_CALL_CENTER,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_CALL_CENTER)) &&
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_CALL_CENTER,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_CALL_CENTER)) === "yes"
    ? true
    : false;

export const IS_EMPLOYEE_VAL =
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_EMPLOYEE,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_EMPLOYEE)) &&
  (window.location.search.includes("miniapp") && window.ma
    ? window.ma.getStorage({
        key: IS_EMPLOYEE,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(IS_EMPLOYEE)) === "yes"
    ? true
    : false;

export const CLASS_TYPE = (
  window.location.search.includes("miniapp") && window.ma && window.ma
    ? window.ma.getStorage({
        key: B2B_ClASS_TYPE,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(B2B_ClASS_TYPE)
)
  ? window.location.search.includes("miniapp") && window.ma && window.ma
    ? window.ma.getStorage({
        key: B2B_ClASS_TYPE,
        success(res) {
          return res;
        },
      })
    : localStorage.getItem(B2B_ClASS_TYPE)
  : null;

export const IS_GUEST =
  !IS_CUSTOMER_VAL && !IS_B2B_VAL && !IS_CALLCENTER_VAL ? true : false;

export const CLASS_VALUE = IS_CUSTOMER_VAL
  ? B2C_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_B_ID
  ? B2BCLASSTYPE_B_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_N_ID
  ? B2BCLASSTYPE_N_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_C_ID
  ? B2BCLASSTYPE_C_ID_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_E_ID
  ? B2BCLASSTYPE_E_NAME
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_S_ID
  ? B2BCLASSTYPE_S_NAME
  : "Guest";

export const CLASS_VALUE_ID = IS_CUSTOMER_VAL
  ? B2C_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_B_ID
  ? B2BCLASSTYPE_B_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_N_ID
  ? B2BCLASSTYPE_N_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_C_ID
  ? B2BCLASSTYPE_C_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_E_ID
  ? B2BCLASSTYPE_E_ID
  : IS_B2B_VAL && CLASS_TYPE === B2BCLASSTYPE_S_ID
  ? B2BCLASSTYPE_S_ID
  : GUEST_ID;
