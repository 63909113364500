//import { ApolloClient } from "apollo-boost";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useState } from "react";
//import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { isAuthenticated } from "./lib/auth";
import { IS_CUSTOMER_VAL } from "./config/typecheck";

console.log(isAuthenticated(), "clientv3", typeof isAuthenticated());

const clientV3 = (endpoint) => {
  const authLink = setContext((_, { headers }) => {
    const tokenType = window.location.search.includes("miniapp")
      ? window?.ma?.getStorage({
          key: "token_type",
          success(res) {
            console.log("success access token", res);
          },
          fail(er) {
            console.log(er, "fail access token");
          },
        })
      : localStorage.getItem(TOKEN_TYPE);
    const token = window.location.search.includes("miniapp")
      ? window?.ma?.getStorage({
          key: "access_token",
          success(res) {
            console.log("success access token", res);
          },
          fail(er) {
            console.log(er, "fail access token");
          },
        })
      : localStorage.getItem(AUTH_TOKEN);
    return {
      headers: {
        ...headers,
        authorization: token ? `${tokenType} ${token}` : "",
      },
    };
  });
  console.log(
    isAuthenticated(),
    "clientv34",
    typeof isAuthenticated(),
    localStorage.getItem("kbzAuth"),
    IS_CUSTOMER_VAL,
  );
  if (window.location.search.includes("miniapp")) {
    isAuthenticated().then((authenticated) => {
      if (authenticated) {
        sessionStorage.setItem("kbzAuth", true);
      } else {
        sessionStorage.setItem("kbzAuth", false);
      }
    });
  }
  const httpLinkV3 = new createHttpLink({
    uri:
      process.env.REACT_APP_BE_SERVER +
      `${
        endpoint
          ? endpoint
          : (window.location.search.includes("miniapp") &&
              (localStorage.getItem("kbzAuth") === "false" ||
                localStorage.getItem("kbzAuth") === "undefined")) ||
            (!window.location.search.includes("miniapp") && !isAuthenticated()) //for guest
          ? //sessionStorage.getItem("kbzAuth") === "false"
            process.env.REACT_APP_GQL_GUEST
          : //sessionStorage.getItem("kbzAuth") === "true" &&
          ((window.location.search.includes("miniapp") &&
              localStorage.getItem("kbzAuth") === "true") ||
              (!window.location.search.includes("miniapp") &&
                isAuthenticated())) &&
            IS_CUSTOMER_VAL //for customer
          ? process.env.REACT_APP_GQL_CUSTOMER
          : process.env.REACT_APP_GQL_DEFAULT // for b2b and others
      }`,
    // uri:
    //   process.env.REACT_APP_BE_SERVER +
    //   `${
    //     endpoint
    //       ? endpoint
    //       : !isAuthenticated() //for guest
    //       ? process.env.REACT_APP_GQL_GUEST
    //       : isAuthenticated() && IS_CUSTOMER_VAL //for customer
    //       ? process.env.REACT_APP_GQL_CUSTOMER
    //       : process.env.REACT_APP_GQL_DEFAULT // for b2b and others
    //   }`,
  });

  return new ApolloClient({
    link: authLink.concat(httpLinkV3),
    cache: new InMemoryCache(),
  });
};

export default clientV3;
