import { AUTH_TOKEN } from "../config/constants";

export const expireDate = (num) => {
  num = num * 1000;
  var now = Date.now();
  num = now + num;
  var date = new Date(num);
  return date.toString();
};
const isExpired = () => {
  //return new Promise((resolve, reject) => {
  // if (window.location.search.includes("miniapp") && window?.ma) {
  window?.ma?.getStorage({
    key: "expires_in",
    success(res) {
      const expirationTime = new Date(
        typeof res === "string" ? JSON.parse(res) : res,
      ).getTime();
      return expirationTime > Date.now();
    },
    fail(er) {
      console.log("expirein_err", er);
      return er; // Handle error if needed
    },
  });
  // } else {
  //   // Fallback to using localStorage if not in miniapp
  //   const expirationTime = new Date(
  //     localStorage.getItem("expires_in"),
  //   ).getTime();
  //   resolve(expirationTime <= Date.now());
  // }
  //});
};
// export const isExpire = () => {
//   (window.location.search.includes("miniapp") && window.ma
//   ?
//  (return new Promise((resolve, reject) => {
//     window?.ma?.getStorage({
//       key: "expires_in",
//       success(res) {
//         try {
//           const expirationDate = new Date(JSON.parse(res));
//           console.log("expires_in", expirationDate);

//           // Compare the expiration date to the current date
//           resolve(expirationDate > Date.now() ? false : true); // false if not expired, true if expired
//         } catch (error) {
//           console.error("Error parsing expires_in:", error);
//           reject(error);
//         }
//       },
//       fail(error) {
//         console.log("expirein_err", error);
//         reject(error);
//       },
//     });
//   })): new Date(localStorage.getItem("expires_in")))>
//      Date.now() ? false
//          : true;
// };
// console.log(
//   window?.ma?.getStorage({
//     key: "expires_in",
//     success(res) {
//       console.log("expires_in", new Date(JSON.parse(res)), new Date(res));
//       return new Date(JSON.parse(res));
//     },
//     fail(er) {
//       console.log("expirein_err", er);
//     },
//   }),
//   "exp",
//   isExpire(),
// );
// // Usage example
// isExpire()
//   .then((expired) => {
//     if (expired) {
//       console.log("The token has expired.");
//     } else {
//       console.log("The token is still valid.");
//     }
//   })
//   .catch((error) => {
//     console.error("Error checking expiration:", error);
//   });

// Check token expiration for local storage (non-miniapp)
export const isExpire = () =>
  new Date(localStorage.getItem("expires_in")) > Date.now() ? false : true;

// Asynchronous function to check token availability in miniapp
const checkTokenAvailability = () => {
  // window?.ma?.getStorage({
  //   key: "access_token",
  //   success(resAc) {
  //     const token = typeof ress === "string" ? JSON.parse(resAc) : resAc;
  //     return token;
  //     console.log("Parsed token:", token, "| isTokenAvailable:", token);
  //   },
  //   fail(er) {
  //     console.log(er, "fail access");
  //   },
  // });
  return new Promise((resolve) => {
    window?.ma?.getStorage({
      key: "access_token",
      success(res) {
        try {
          const token = typeof res === "string" ? JSON.parse(res) : res;
          console.log("res token", res);
          resolve(!!token); // Resolve with true if token exists, otherwise false
        } catch (e) {
          console.error("Error parsing access token:", e);
          resolve(false); // Resolve as false if parsing fails
        }
      },
      fail(err) {
        console.log("Failed to retrieve access_token:", err);
        resolve(false); // Resolve as false if retrieval fails
      },
    });
  });
};

// Example usage:
// checkTokenAvailability().then((isAvailable) => {
//   console.log("Token availability:", isAvailable);
// });
export const isAuthenticated = () => {
  if (window.location.search.includes("miniapp")) {
    return checkTokenAvailability()
      .then((isTokenAvailable) => {
        console.log("Token availability:", isTokenAvailable);
        //return isTokenAvailable;
        return isTokenAvailable; // Resolves to true or false
      })
      .catch((error) => {
        console.error("Error during miniapp authentication check:", error);
        return false; // Resolves to false if there's an error
      });
  } else return localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;
};

// Example usage

// Main function to check authentication status
// export const isAuthenticated = (callback) => {
//   // console.log("Checking authentication status...");
//   const isTokenAvailable = checkTokenAvailability();
//   // if (window.location.search.includes("miniapp") && window?.ma) {
//   // For miniapp environment
//   try {
//     console.log(
//       checkTokenAvailability(),
//       "checkTokenAvailability()",
//       checkTokenAvailability((isTokenAvailable) => {
//         callback(isTokenAvailable); // Return true or false via callback
//       }),
//     );

//     // const expired = isExpired();

//     // const isValid = isTokenAvailable === undefined ? false : true;
//     // //&& !expired;
//     // console.log(
//     //   "Final authentication status (miniapp)dd:",
//     //   checkTokenAvailability(),
//     //   isValid ? "Authenticated" : "Not authenticated",
//     // );

//     return isTokenAvailable === undefined || false ? false : true; // Resolve with the final authentication status (true or false)
//   } catch (error) {
//     console.error("Error during miniapp authentication check:", error);
//     return false; // Return false in case of any errors
//   }
//   // } else {
//   //   // For non-miniapp environments, use localStorage
//   //   const tokenAvailable = !!localStorage.getItem(AUTH_TOKEN);
//   //   const tokenExpired = isExpire();
//   //   const isAuthenticated = tokenAvailable && !tokenExpired;

//   //   console.log(
//   //     "Final authentication status (non-miniapp):",
//   //     isAuthenticated ? "Authenticated" : "Not authenticated",
//   //   );
//   //   return isAuthenticated; // Return final result
//   // }
// };

export const GetParamByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
